const startCanvas = {};
startCanvas.signUp = (urlImage, id) => {
  var url = urlImage;
  var canvas = document.getElementById(id);
  // canvas.style.cursor = 'url(' + cursor.toDataURL() + '), auto';
  var ctx = canvas.getContext("2d");
  var img = new Image();
  img.src = url;
  img.onload = function () {
    var width = Math.min(500, img.width);
    var height = img.height * (width / img.width);

    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);
  };

  var isPress = false;
  var old = null;
  canvas.addEventListener("mousedown", function (e) {
    // console.log("mousedown");
    isPress = true;
    old = { x: e.offsetX, y: e.offsetY };
  });
  canvas.addEventListener("mousemove", function (e) {
    // console.log("mousemove");
    if (isPress) {
      var x = e.offsetX;
      var y = e.offsetY;

      ctx.globalCompositeOperation = "destination-out";

      ctx.beginPath();
      ctx.arc(x, y, 10, 0, 2 * Math.PI);
      ctx.fill();

      ctx.lineWidth = 20;
      ctx.beginPath();
      ctx.moveTo(old.x, old.y);
      ctx.lineTo(x, y);
      ctx.stroke();

      old = { x: x, y: y };
    }
  });
  canvas.addEventListener("mouseup", function (e) {
    isPress = false;
    // console.log("mouseup");
  });
  // Configurar eventos táctiles para dispositivos móviles, etc.
  canvas.addEventListener("touchstart", function (e) {
    //  console.log("touchstart");
    e.preventDefault();
    var rect = e.target.getBoundingClientRect();
    var bodyRect = document.body.getBoundingClientRect();

    e.offsetX = e.targetTouches[0].pageX - (rect.left - bodyRect.left);
    e.offsetY = e.targetTouches[0].pageY - (rect.top - bodyRect.top);
    isPress = true;
    old = { x: e.offsetX, y: e.offsetY };
  });
  canvas.addEventListener("touchmove", function (e) {
    // console.log("touchmove");
    e.preventDefault();
    if (isPress) {
      var rect = e.target.getBoundingClientRect();
      var bodyRect = document.body.getBoundingClientRect();

      e.offsetX = e.targetTouches[0].pageX - (rect.left - bodyRect.left);
      e.offsetY = e.targetTouches[0].pageY - (rect.top - bodyRect.top);
      var x = e.offsetX;
      var y = e.offsetY;
      ctx.globalCompositeOperation = "destination-out";

      ctx.beginPath();
      ctx.arc(x, y, 10, 0, 2 * Math.PI);
      ctx.fill();

      ctx.lineWidth = 20;
      ctx.beginPath();
      ctx.moveTo(old.x, old.y);
      ctx.lineTo(x, y);
      ctx.stroke();

      old = { x: x, y: y };
    }
  });
  canvas.addEventListener("touchend", function (e) {
    // console.log("touchend");
    isPress = false;
  });
};

export default startCanvas;
