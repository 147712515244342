import React from "react";
// PRIMEREACT
import { Dialog } from "primereact/dialog";
// PRIMEREACT

export default function Messages({
  showMessage,
  actionHidden,
  message,
  headerMessage,
  color,
}) {
  return (
    <Dialog
      visible={showMessage}
      onHide={() => actionHidden()}
      position="center"
      // footer={dialogFooter}
      showHeader={false}
      style={{ width: "80%", borderRadius: "30px" }}
      breakpoints={{ "960px": "75%" }}
    >
      <>
        <div className="grid">
          {headerMessage ? (
            <>
              <div className="col-12 mt-5">
                <h2 className="titleModal" style={{ color: `var(--${color})` }}>{headerMessage}</h2>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="col-12 contentMessage">{message}</div>

          <div className="col-6 col-offset-2">
            <button
              className="btn-basic"
              type="submit"
              onClick={() => actionHidden()}
            >
              Cerrar
            </button>
          </div>
        </div>
      </>
    </Dialog>
  );
}
