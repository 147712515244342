const audio = {};

audio.SoundBT = () => {
  import("../../assets/sound/btn.mp3")
    .then((module) => {
      const soundBT = new Audio(module.default);
      soundBT.play().catch((error) => {
        console.log(error);
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export default audio;