/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
//PAGES
import Loader from "./pages/Loader";
import Error404 from "./pages/Error404";
import Body from "./pages/Body";
import RegisterFree from "./pages/Register/RegisterFree";
import Header from "./pages/Header";
//ESTILOS
import "./index.css";
//FIREBASE
import { onAuthStateChanged } from "firebase/auth";
import { onSnapshot, query, doc } from "firebase/firestore";
import { db, auth } from "./firebase";

function App() {
  const [dataUser, setDataUser] = useState(null);
  const [configurations, setConfigurations] = useState([]);
  const [theme, setTheme] = useState([]);

  //Datos de usuario
  useEffect(() => {
    const unlistens = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        onAuthStateChanged(auth, (authUser) => {
          if (authUser) {
            return onSnapshot(doc(db, "usersClients", authUser.uid), (docc) => {
              let userData = docc.data();
              setDataUser({ ...userData, userId: authUser.uid });
            });
          }
        });
      } else {
        setDataUser(null);
      }
    });
    return () => {
      unlistens();
    };
  }, []);

  useEffect(() => {
    //Datos de configuración
    const qConfig = query(doc(db, "config", "configurations"));
    const qTheme = query(doc(db, "config", "theme"));

    const unlistenqConfig = onSnapshot(qConfig, (docs) => {
      let configF = docs.data();
      setConfigurations(configF);
    });

    //Datos de Thema
    const unlistenqTheme = onSnapshot(qTheme, (docs) => {
      let configTh = docs.data();
      setTheme(configTh);
    });

    return () => {
      unlistenqConfig();
      unlistenqTheme();
    };
  }, []);

  //Espera para mostrar Datos
  if (configurations.length === 0 && theme.length === 0 && dataUser === null) {
    return <Loader />;
  }

  return (
    <>
      <Header theme={theme} />
      {configurations && configurations.on === false ? (
        <Error404 />
      ) : dataUser ? (
        <Body
          dataUser={dataUser}
          setDataUser={setDataUser}
          configurations={configurations}
        />
      ) : (
        <RegisterFree configurations={configurations} />
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
