import React, { useState } from "react";
import "./Register.css";
//FORMIK
import * as Yup from "yup";
import { useFormik } from "formik";
//PRIMEREACT
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { classNames } from "primereact/utils";
//COMPONENTES
import Messages from "../Others/Messages";
import audio from "../Others/sound";
import { toast } from "react-toastify";
import authentication from "../../services/authentication";
import fglobales from "../../services/fglobales";
import regex from "../../services/regex";
//MEDIA

function Register({ configurations }) {
  const [submitted, setSubmitted] = useState(false);
  //CONTROL DE MENSAJES -->
  const [showMessage, setShowMessage] = useState(false);
  const [colorIcon, setColorIcon] = useState("");
  const [headerMessage, setHeaderMessage] = useState("");
  const [message, setMessage] = useState("");
  //CONTROL DE MENSAJES <--

  const formik = useFormik({
    initialValues: {
      names: "",
      dni: "",
      mobilePhone: "",
      emailFrom: "",
      city: "",
      factura: "",
      ppd: "",
    },
    validationSchema: Yup.object({
      names: Yup.string().required("El nombre completo es obligatorio"),
      dni: Yup.string().required("El documento de identidad es obligatorio"),
      mobilePhone: Yup.string()
        .matches(regex.mobilePhone, "El campo debe tener 10 dígitos")
        .required("Este campo es requerido"),
      emailFrom: Yup.string()
        .email("El correo electronico es invalido")
        .required("El correo electrónico es obligatorio"),
      city: Yup.object().required("La ciudad es obligatoria"),
      factura: Yup.string().required("El número de la factura es obligatorio"),
      ppd: Yup.boolean()
        .required("El tratamiento de datos debe ser aceptado.")
        .oneOf([true], "El tratamiento de datos, debe ser aceptado."),
    }),
    onSubmit: async (formData) => {
      try {
        setSubmitted(true);

        const newUser = formData;
        newUser.factura = `${newUser.factura}`;
        newUser.factura = newUser.factura.trim();
        newUser.emailFrom = newUser.emailFrom.trim();
        newUser.email = `${newUser.factura}999@raspabevgo.com`;
        newUser.password = `${newUser.factura}999`;
        newUser.phone = newUser.mobilePhone.replace(regex.clearCracter, "");

        const dniExist = await fglobales.getExistNoId(
          "usersClients",
          "factura",
          formData.factura
        );

        if (!dniExist) {
          authentication
            .signUpWithEmailAddressAndPassword(
              newUser.email,
              newUser.password,
              formData,
              configurations.number
            )
            .then((result) => {
              setSubmitted(false);
              toast.success(result);
            })
            .catch((error) => {
              setSubmitted(false);
              toast.error(error);
              console.log(error);
            });
        } else {
          authentication
            .signIn(newUser.email, newUser.password)
            .then((result) => {
              setSubmitted(false);
              toast.success(result);
            })
            .catch((error) => {
              setSubmitted(false);
              toast.error(error);
              console.log(error);
            });
        }
      } catch (error) {
        toast.error(error);
        console.log(error);
        setSubmitted(false);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const actionHiddenMessage = () => {
    setShowMessage(false);
    hideDialog();
  };

  const hideDialog = () => {
    setShowMessage(false);
    setSubmitted(false);
  };

  const openTermin = () => {
    setColorIcon("secondary-color");
    setHeaderMessage("Tratamiento de datos personales.");
    setMessage(
      <>
        <div className="grid">
          <div className="col-12 md:px-6">
            <h2>POLÍTICAS DE PRIVACIDAD</h2>
            <br />
            <p className="mensajeP">
              <a
                href={configurations.habeasData}
                target="_blank"
                rel="noreferrer"
              >
                Tratamineto de datos personales
              </a>
            </p>
          </div>
        </div>
        <br />

        <div className="grid">
          <div className="col-12 px-2 md:px-6">
            <h2>TERMINOS Y CONDICIONES</h2>
            <br />
            <p className="mensajeP">
              <a
                href={configurations.termsAndConditions}
                target="_blank"
                rel="noreferrer"
              >
                Terminos y condiciones raspa y gana
              </a>
            </p>
          </div>
        </div>
      </>
    );
    setShowMessage(true);
  };

  const citys = [
    { name: "Barranquilla", code: 0 },
    { name: "Cartagena", code: 1 },
    { name: "Santa Marta", code: 2 },
    { name: "Montería", code: 3 },
    { name: "Sincelejo", code: 4 },
    { name: "Valledupar", code: 5 },
    { name: "Bucaramanga", code: 6 },
  ];

  return (
    <>
      <div className="grid grid-nogutter over mb-5">
        <div className="col-12 md:px-5">
          {/* <h1 className="px-5 mb-4">Formulario ingreso</h1> */}

          <div className="grid">
            <div className="col-10 col-offset-1 container-form">
              <form onSubmit={formik.handleSubmit} className="p-fluid">
                <div className="grid pb-2">
                  <div className="col-12">
                    <p className="pForm">Nombre completo: *</p>
                    <InputText
                      id="names"
                      name="names"
                      value={formik.values.names}
                      onChange={formik.handleChange}
                      disabled={submitted}
                      className={classNames({
                        "p-invalid": isFormFieldValid("names"),
                        "input-filled":
                          formik.values.names &&
                          formik.values.names.trim() !== "",
                      })}
                    />

                    {getFormErrorMessage("names")}
                  </div>
                </div>

                <div className="grid pb-2">
                  <div className="col-12">
                    <p className="pForm">Cédula: *</p>
                    <InputText
                      id="dni"
                      name="dni"
                      type="tel"
                      value={formik.values.dni}
                      onChange={formik.handleChange}
                      disabled={submitted}
                      className={classNames({
                        "p-invalid": isFormFieldValid("dni"),
                        "input-filled":
                          formik.values.dni && formik.values.dni !== "",
                      })}
                    />
                    {getFormErrorMessage("dni")}
                  </div>
                </div>

                <div className="grid pb-2">
                  <div className="col-12">
                    <p className="pForm">Celular: *</p>
                    <InputMask
                      id="mobilePhone"
                      name="mobilePhone"
                      type="tel"
                      mask="(999) 999-9999"
                      value={formik.values.mobilePhone}
                      onChange={(e) =>
                        formik.setFieldValue("mobilePhone", e.value)
                      }
                      disabled={submitted}
                      className={classNames({
                        "p-invalid": isFormFieldValid("mobilePhone"),
                        "input-filled": formik.values.mobilePhone,
                      })}
                    />

                    {getFormErrorMessage("mobilePhone")}
                  </div>
                </div>

                <div className="grid pb-2">
                  <div className="col-12">
                    <p className="pForm">Correo: *</p>
                    <InputText
                      id="emailFrom"
                      name="emailFrom"
                      value={formik.values.emailFrom}
                      onChange={formik.handleChange}
                      disabled={submitted}
                      className={classNames({
                        "p-invalid": isFormFieldValid("emailFrom"),
                        "input-filled": formik.values.emailFrom,
                      })}
                    />
                    {getFormErrorMessage("emailFrom")}
                  </div>
                </div>

                <div className="grid pb-2">
                  <div className="col-12">
                    <p className="pForm">Tienda: *</p>
                    <Dropdown
                      optionLabel="name"
                      options={citys}
                      id="city"
                      name="city"
                      value={formik.values.city}
                      onChange={(e) => {
                        formik.setFieldValue("city", e.value);
                      }}
                      className={classNames(
                        {
                          "p-invalid": isFormFieldValid("city"),
                        },
                        "textForm"
                      )}
                      disabled={submitted}
                    />
                    {getFormErrorMessage("city")}
                  </div>
                </div>

                <div className="grid pb-2">
                  <div className="col-12">
                    <p className="pForm">N° Factura: *</p>
                    <InputText
                      id="factura"
                      name="factura"
                      type="factura"
                      value={formik.values.factura}
                      onChange={formik.handleChange}
                      disabled={submitted}
                      className={classNames({
                        "p-invalid": isFormFieldValid("factura"),
                        "input-filled":
                          formik.values.factura && formik.values.factura !== "",
                      })}
                    />
                    {getFormErrorMessage("factura")}
                  </div>
                </div>

                <div className="grid">
                  <div className="col-12">
                    <div className="field-checkbox">
                      <Checkbox
                        onChange={(e) => formik.setFieldValue("ppd", e.checked)}
                        disabled={submitted}
                        checked={formik.values.ppd}
                        inputId="ppd"
                      />
                      <label
                        htmlFor="binary"
                        className={classNames(
                          {
                            "p-error": isFormFieldValid("ppd"),
                          },
                          "checked"
                        )}
                        onClick={() => {
                          openTermin();
                        }}
                      >
                        Acepto tratamiento de datos personales, terminos y
                        condiciones
                      </label>
                    </div>
                    {getFormErrorMessage("ppd")}
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="grid mt-2">
            <div className="col-12 md:col-6 md:col-offset-3 text-center">
              <button
                className="btn-basic"
                disabled={submitted}
                type="submit"
                onClick={() => {
                  audio.SoundBT();
                  formik.submitForm();
                }}
              >
                Ingresar
              </button>
            </div>
          </div>
        </div>
      </div>

      <Messages
        showMessage={showMessage}
        actionHidden={actionHiddenMessage}
        headerMessage={headerMessage}
        message={message}
        color={colorIcon}
      />
    </>
  );
}

export default Register;
