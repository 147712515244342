import {
  query,
  where,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
  setDoc,
  serverTimestamp,
  runTransaction,
  increment,
  // orderBy,
} from "firebase/firestore";
import { signOut } from "firebase/auth";
import { db, auth } from "../firebase";

const fglobales = {};

fglobales.getExistNoId = async (collectionText, variableText, value) => {
  const queryRef = query(
    collection(db, collectionText),
    where(variableText, "==", value)
  );
  const docSnap = await getDocs(queryRef);

  return docSnap.size > 0;
};

fglobales.logoutSesion = (setDataUser) => {
  signOut(auth)
    .then(function () {
      setDataUser(null);
    })
    .catch(function (error) {
      // An error happened.
      console.log(error);
    });
};

fglobales.addDocs = async (collectionText, data, dataUser) => {
  const ref = doc(collection(db, collectionText));
  try {
    await setDoc(ref, {
      ...data,
      id: ref.id,
      timestamp: serverTimestamp(),
      names: dataUser.names,
      surnames: dataUser.surnames,
      medio: dataUser.medio,
      status: 0,
    });
    return { status: true, message: "Transación Exitosa" };
  } catch (e) {
    console.log("Transacción fallida: ", e);
    return { status: false, message: e };
  }
};

fglobales.addOrder = async (collectionText, data, dataUser) => {
  const configDocRef = doc(db, "config", "counters");
  const orderDocRef = doc(collection(db, collectionText));

  try {
    await runTransaction(db, async (transaction) => {
      const configDoc = await transaction.get(configDocRef);
      const order = configDoc.data().order;
      let number;

      number = order + 1;
      transaction.update(configDocRef, { order: increment(1) });

      transaction.set(orderDocRef, {
        ...data,
        id: orderDocRef.id,
        timestamp: serverTimestamp(),
        names: dataUser.names,
        surnames: dataUser.surnames,
        medio: dataUser.medio,
        number,
        status: 0,
      });
    });
    return { status: true, message: "Transación Exitosa" };
  } catch (e) {
    console.log("Transacción fallida: ", e);
    return { status: false, message: e };
  }
};

fglobales.savePrize = (collectionText, prize, userId, prizeId, winner) => {
  console.log("Entro a global");
  const userDocRef = doc(db, collectionText, userId);
  const prizeReference = doc(db, "inventario", prizeId);

  try {
    updateDoc(userDocRef, {
      prize: prize,
      winner: winner,
    });

    try {
      updateDoc(prizeReference, {
        restan: increment(-1),
        entregados: increment(1),
      });
      return true;
    } catch (e) {
      console.log("Transacción fallida: ", e);
      return false;
    }
  } catch (e) {
    console.log("Transacción fallida: ", e);
    return false;
  }
};

fglobales.getAccesCode = async (collectionText, variableText, value) => {
  const queryRef = query(
    collection(db, collectionText),
    where(variableText, "==", value)
  );

  try {
    const docSnap = await getDocs(queryRef);
    let docData = [];

    docSnap.forEach((doc) => {
      docData = doc.data();
    });

    if (docData.length === 0) {
      return false;
    } else {
      if (docData.status === true) {
        return docData;
      } else {
        return 0;
      }
    }
  } catch (e) {
    console.log("Transacción fallida: ", e);
    return false;
  }
};

fglobales.updateRaspaLogin = (
  collectionTextUser,
  dataUser,
  collectionText,
  codeId,
  dataAcces
) => {
  const userDocRef = doc(db, collectionTextUser, dataUser.id);
  const acessCodeDocRef = doc(db, collectionText, codeId);

  let numPrize = dataUser.ingresoAttempts + 1;

  try {
    updateDoc(acessCodeDocRef, {
      status: false,
      timestamp: serverTimestamp(),
    });

    try {
      setDoc(
        userDocRef,
        {
          raspaLogin: true,
          // [`prize${numPrize}`]: dataAcces.prize,
          [`codePrize${numPrize}`]: dataAcces.code,
          [`prizeName${numPrize}`]: dataAcces.namePrize,
          [`winnerPrize${numPrize}`]: dataAcces.winner,
          [`imgUrlPrize${numPrize}`]: dataAcces.imgUrl,
          ingresoAttempts: increment(1),
        },
        { merge: true }
      );
    } catch (e) {
      console.log("Transacción fallida: ", e);
    }
  } catch (e) {
    console.log("Transacción fallida: ", e);
  }
};

fglobales.updatePageBack = (collectionTextUser, dataUser, setLock) => {
  const userDocRef = doc(db, collectionTextUser, dataUser.id);

  try {
    updateDoc(userDocRef, {
      raspaLogin: false,
      attempts: increment(-1),
    });

    setLock(false);
  } catch (e) {
    console.log("Transacción fallida: ", e);
  }
};

fglobales.logoutSesionInterna = (collectionTextUser, dataUser, setLock) => {
  const userDocRef = doc(db, collectionTextUser, dataUser.id);

  try {
    updateDoc(userDocRef, {
      raspaLogin: false,
      attempts: increment(-1),
    });

    setLock(false);
  } catch (e) {
    console.log("Transacción fallida: ", e);
  }
};

//Obtener datos de un documento en especifico
fglobales.getDataDocument = async (collectionText, documentText) => {
  try {
    const userDocRef = doc(db, collectionText, documentText);
    const docSnapshot = await getDoc(userDocRef);

    if (docSnapshot.exists()) {
      // El documento existe, puedes acceder a los datos con docSnapshot.data()
      return docSnapshot.data();
    } else {
      // El documento no existe
      throw new Error("El documento no existe");
    }
  } catch (error) {
    // Manejo de errores
    console.error("Error al obtener los datos del documento:", error.message);
    throw error;
  }
};

//Actualizar un docuemnto, en este caso la coleccion del usuario con los datos del premio
fglobales.updateDocUser = async (collectionText, dataUser, dataPrize) => {
  const userDocRef = doc(db, collectionText, dataUser.userId);
  const prizeDocRef = doc(db, "accessCode", `${dataUser.numberPrize}`);

  try {
    await updateDoc(userDocRef, {
      dataPrize: { ...dataPrize },
    });

    try {
      await updateDoc(prizeDocRef, {
        status: true,
        timestamp: serverTimestamp(),
      });

      return { message: "Transacción correcta", status: true };
    } catch (e) {
      console.log("Transacción fallida: ", e);
      return { message: "Transacción fallida", status: false, error: e };
    }
  } catch (e) {
    console.log("Transacción fallida: ", e);
    return { message: "Transacción fallida", status: false, error: e };
  }
};

export default fglobales;
