import React, { useEffect, useState } from "react";
import "./RaspaGana.css";
import startCanvas from "./js";
import { toast } from "react-toastify";
//COMPONENTEA
import Loader from "../Loader";
//MEDIA
import habilitadamedia from "../../assets/images/raspa-web.png";
import habilitadamobile from "../../assets/images/raspa-movil.png";
import inhabilitadamedia from "../../assets/images/bloqueado-web.png";
import inhabilitadamobile from "../../assets/images/bloqueado-movil.png";
//SERVICIOS
import fglobales from "../../services/fglobales";

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

function RaspaGana({ dataUser, configurations, dataPrize }) {
  const [lock, setLock] = useState(false);
  let isPageWide = useMediaQuery("(min-width: 800px)");

  const updateUserPrizeData = async () => {
    if (!lock && configurations !== null && configurations.blocking) {
      if (isPageWide) {
        startCanvas.signUp(habilitadamedia, "canvas");
      } else {
        startCanvas.signUp(habilitadamobile, "canvas");
      }
      setLock(true);
    }

    if (!dataPrize.status) {
      const docUpdate = await fglobales.updateDocUser(
        "usersClients",
        dataUser,
        dataPrize
      );

      if (!docUpdate.status) {
        toast.error(docUpdate.error);
      }
    }
  };

  return (
    <>
      <div className="grid grid-nogutter over maximus">
        <div className="col-10 col-offset-1 mb-3">
          <p>
            Hola <b>{dataUser.names} </b>bienvenido al raspa y gana, por favor
            actívalo dándole clic a la tarjeta.
          </p>
        </div>

        <div className="col-12 mt-9 p-0">
          <div className="raspaygana">
            {dataUser ? (
              <>
                {isPageWide ? (
                  <>
                    <div
                      className="box"
                      style={{
                        background: `url(${
                          lock &&
                          configurations !== null &&
                          configurations.blocking
                            ? dataPrize.imgUrl
                            : inhabilitadamedia
                        })`,
                      }}
                      onClick={() => {
                        updateUserPrizeData();
                      }}
                    >
                      <div></div>
                      <canvas id="canvas"></canvas>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="box"
                      style={{
                        background: `url(${
                          lock &&
                          configurations !== null &&
                          configurations.blocking
                            ? dataPrize.imgUrl
                            : inhabilitadamobile
                        })`,
                      }}
                      onClick={() => {
                        updateUserPrizeData();
                      }}
                    >
                      <canvas id="canvas"></canvas>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <Loader />
              </>
            )}
          </div>
        </div>

        <div className="col-10 col-offset-1 mt-4 mb-6">
          <p>
            Si estas en el computador mantén el clic sostenido sobre la tarjeta
            para raspar y revelar el premio, si estas desde un smartphone
            desliza tu dedo sobre la tarjeta
          </p>
        </div>
      </div>
    </>
  );
}

export default RaspaGana;
