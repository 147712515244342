import React, { useEffect, useState } from "react";
import "./Body.css";
//PAGES
import RaspaGana from "../RaspaGana";
import Logout from "../Logout";
//SERVICIOS
import fglobales from "../../services/fglobales";

function Body({ dataUser, setDataUser, configurations }) {
  const [dataPrize, setDataPrize] = useState([]);

  useEffect(() => {
    const asincroFuntion = async () => {
      try {
        const docPrize = await fglobales.getDataDocument(
          "accessCode",
          `${dataUser.numberPrize}`
        );

        setDataPrize(docPrize);
      } catch (error) {
        console.log(error);
      }
    };

    asincroFuntion();
  }, [dataUser]);

  return (
    <>
      <Logout setDataUser={setDataUser} />
      <RaspaGana
        dataUser={dataUser}
        configurations={configurations}
        dataPrize={dataPrize}
      />
    </>
  );
}

export default Body;
