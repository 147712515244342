import React from "react";
//ESTILOS
import "./Logout.css";
//SERVICIOS
import fglobales from "../../services/fglobales";

function Logout({ setDataUser }) {
  const logouts = () => {
    fglobales.logoutSesion(setDataUser);
  };

  return (
    <>
      <div className="grid grid-nogutter">
        <div className="col-12">
          <button
            type="button"
            className="btn-logout"
            onClick={() => {
              logouts();
            }}
          >
            Salir
          </button>
        </div>
      </div>
    </>
  );
}

export default Logout;
