import React from "react";
//ESTILOS
import "./Header.css";
//PRIMEREACT

function Header({ theme }) {
  return (
    <>
      <header className="grid grid-nogutter">
        <div className="col-12 md:col-8 md:col-offset-2 logo">
          <img src={theme ? theme.logo : "logo"} alt="Logo" />
        </div>
      </header>
    </>
  );
}

export default Header;
